.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

div.loading-div {
  position: absolute;
  /* top: 0px;
  left: 0px; */
  width: 100vw;
  height: 100vh;
  background-color: rgb(21, 28, 43);
  color: white;
  z-index: 99999;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* @-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
} */

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* @-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
} */

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

#overlayDebug {
  position: "absolute";
  top: 0px;
  left: 0px;
  font-size: 11px !important;
  font-family: Poppins;
  pointer-events: "none !important";
  z-index: 2;
  color: red !important;
  /* font-weight: bold !important; */
  pointer-events: auto !important;
  background-color: rgba(255, 255, 255, 0.7) !important;
  max-width: 300px;
}

#sceneSwitcher {
  background-color: #171717;
  /* opacity: 0; */
  /* pointer-events: none; */
  /* display: none; */
}

.fadeIn {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

.fadeOut {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

.overlay-container {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  width: 80vw;
  pointer-events: all;
  /* border: 25px solid black; */
  /* border-radius: 15px; */
  height: 100vh;
  width: 100vw;
  /* padding: 20px; */
  /* color: white; */
  align-items: center;
}

.overlay-content {
  margin: auto;
  width: 100vw;
  height: 100vh;
  /* color: red; */
  overflow: auto;
  /* padding: 50px; */
}

#debugConsole {
  font-size: 9px !important;
  /* font-family: Poppins; */
}

/** BOOTSTRAP **/
.container,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  max-width: 1320px;
  background-color: rgba(255, 255, 255, 0.7);
  min-height: 100vh;
  position: relative;
  padding-top: 15px;
  padding-bottom: 15px;
}

.close-butt-div {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 999;
}

.btn-theme {
  background-color: rgb(21, 28, 43);
  color: white;
}
.btn-theme:hover {
  background-color: rgba(21, 28, 43, 0.7);
  color: rgba(255, 255, 255, 0.7);
}

.theme-description {
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  color: rgb(255, 255, 255);
  display: block;
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  height: 21px;
  letter-spacing: 0.10000000149011612px;
  line-height: 21.700000762939453px;
}

.theme-header {
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  color: rgb(255, 255, 255);
  display: block;
  font-family: Poppins;
  font-size: 48px;
  font-weight: bold;
  letter-spacing: normal;
  line-height: 57.599998474121094px;
}

.text-theme {
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  color: rgb(255, 255, 255);
  display: block;
  font-family: Poppins;
  font-size: 22px;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 30.799999237060547px;
}
